import { inject, onMounted, onUnmounted, reactive } from "vue";
import { store } from "../store";
import Segments from "@/modules/Segments";
import { useRoute } from "vue-router";

export default function Trending(limit = 0) {
  const axios = inject("axios");
  const { ProductListViewed, ProductListFiltered } = Segments();
  const route = useRoute();

  function saveProductCount(count) {
    store.dispatch("productCountAction", count);
  }

  //  state data
  const states = reactive({
    loading: true,
    data: [],
    limitData: [],
    error: "",
  });
  const getStates = async () => {
    await axios.api
      .get("/get-states")
      .then((resp) => {
        if (resp.status) {
          states.data = resp.data.data;
          states.data.unshift({
            id: 55,
            city: "USA",
            state: "USA",
            state_name: "All States",
          });
        } else {
          states.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (states.loading = false));
  };
  //  brand data
  const brands = reactive({
    loading: true,
    data: [],
    limitData: [],
    error: "",
  });

  const getbrands = (val) => {
    let arr = val.value.map((r) => r.slug);
    brands.loading = true;
    axios.api
      .get("/brands", { params: { category: arr } })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data.data;
          if (store.getters.cart.price_list_id == "33") {
            brands.data = data;
          } else {
            brands.data = data.filter((e) => !e.name.includes("GGG"));
          }
        } else {
          brands.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (brands.loading = false));
  };

  // cat
  const categories = reactive({
    loading: true,
    data: [],
    error: "",
  });

  const getCategories = async (val) => {
    let arr = val.value.map((r) => r.slug);
    categories.loading = true;
    axios.api
      .get("/categories", { params: { brand: arr } })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data.data;
          if (store.getters.cart.price_list_id == "33") {
            categories.data = data;
          } else {
            categories.data = data.filter((e) => !e.name.includes("GGG"));
          }
        } else {
          categories.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (categories.loading = false));
  };

  //  filter  Globally set
  var filter;
  var dataCount;
  const trendBest = reactive({
    loading: true,
    data: [],
    limitData: [],
    error: "",
  });
  const trendUnique = reactive({
    loading: true,
    data: [],
    limitData: [],
    error: "",
  });
  const getTrends = (fil, bthFilter, email = null) => {
    filter = fil;
    dataCount = 0;
    let b_url = "/best-selling-products";
    let u_url = "/unique-products";
    // if(filter.notPurchased){
    // console.log("trend js",'notPurchased' ,filter)
    // console.log("calling....", filter, arr)
    if (email) {
      b_url = "/best-selling/" + email;
      u_url = "/unique/" + email;
    }
    bestSellingData(bthFilter, b_url);
    uniqueData(bthFilter, u_url);
    // }
    // else{
    //     console.log("trend js",'all product' ,filter)
    // }
    // trend.loading =true;
  };
  const bestSellingData = async (bthFilter, url) => {
    trendBest.loading = true;
    await axios.authApi
      .get(url, { params: filter })
      .then((resp) => {
        dataCount += resp.data.data.length;
        saveProductCount(dataCount);
        if (resp.status) {
          trendBest.data = resp.data.data;
          if (filter.category || filter.brand)
            ProductListFiltered(
              route,
              resp.data.data,
              bthFilter,
              "Brand and Category",
              "bestSellingProducts"
            );
          else ProductListViewed(route, resp.data.data, "bestSellingProducts");
        } else {
          trendBest.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (trendBest.loading = false));
  };
  const uniqueData = (bthFilter, url) => {
    trendUnique.loading = true;
    axios.authApi
      .get(url, { params: filter })
      .then((resp) => {
        dataCount += resp.data.data.length;
        saveProductCount(dataCount);
        if (resp.status) {
          trendUnique.data = resp.data.data;
          if (filter.category || filter.brand)
            ProductListFiltered(
              route,
              resp.data.data,
              bthFilter,
              "Brand and Category",
              "uniqueProducts"
            );
          else ProductListViewed(route, resp.data.data, "uniqueProducts");
        } else {
          trendUnique.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (trendUnique.loading = false));
  };

  // function saveProductCount(count){
  //     store.dispatch('productCountAction',count);
  // }
  // const getbrands = () => {
  //     brands.loading = true;
  //     axios.api.get('/brands').then((resp) => {
  //         saveProductCount(resp.data.data.length)
  //         if (resp.status) {
  //             brands.data = resp.data.data;
  //         } else {
  //             brands.error = resp.data.error;
  //         }
  //     }).catch(function(error) {
  //         console.log(error);
  //     }).finally(() => (brands.loading = false));
  // }

  // const newBrands = reactive({
  //     loading: false,
  //     data: [],
  //     limitData: [],
  //     error: ''
  // })

  // const getNewBrands = () => {
  //     newBrands.loading = true;
  //     axios.api.get('/newBrands').then((resp) => {
  //         if (resp.status) {
  //             newBrands.data = resp.data.data;
  //             newBrands.limitData = resp.data.data.slice(0, limit)
  //         } else {
  //             newBrands.error = resp.data.error;
  //         }
  //     }).catch(function(error) {
  //         console.log(error);
  //     }).finally(() => (newBrands.loading = false));
  // }

  // const brandSlug = reactive({
  //     loading: false,
  //     data: [],
  //     error: '',
  //     loadingScroll: false
  // })

  // const route = useRoute();

  // var page = 1;
  // let lastPage = 0;
  // let sort = '';
  // let filter = [];
  // let inStock = null;
  // let load = false;

  // const getBrandsSlug = async() => {
  //     if (axios.isLoggedIn) {
  //         brandSlug.loading = true;
  //         load = true;
  //         axios.authApi.get('/aCollection?brand_slug=' + route.params.slug, {
  //             params: {
  //                 page: page,
  //                 sort: sort,
  //                 stock: inStock,
  //                 category: filter,
  //             }
  //         }).then((resp) => {
  //             saveProductCount(resp.data.data.total)
  //             if (resp.status) {
  //                 brandSlug.data = resp.data.data;
  //                 lastPage = resp.data.data.last_page;
  //                 load = false;

  //                 // Store Filter Options in store(filterOptions)
  //                 store.dispatch('updateOptions', resp.data.filterOptions);
  //             } else {
  //                 brandSlug.error = resp.data.error;
  //             }
  //         }).catch(function(error) {
  //             console.log(error);
  //         }).finally(() => (brandSlug.loading = false));
  //     } else {
  //         brandSlug.loading = true;
  //         load = true;
  //         axios.api.get('/collection?brand_slug=' + route.params.slug, {
  //             params: {
  //                 page: page
  //             }
  //         }).then((resp) => {
  //             saveProductCount(resp.data.data.total)
  //             if (resp.status) {
  //                 brandSlug.data = resp.data.data;
  //                 lastPage = resp.data.data.last_page;
  //                 load = false;
  //             } else {
  //                 brandSlug.error = resp.data.error;
  //             }
  //         }).catch(function(error) {
  //             console.log(error);
  //         }).finally(() => (brandSlug.loading = false));
  //     }
  // }

  // const sortChange = (value) => {
  //     page = 1;
  //     sort = value;
  //     brandSlug.data = [];
  //     getBrandsSlug();
  // }

  // const stock = (stock) => {
  //     page = 1;
  //     brandSlug.data = [];
  //     inStock = stock;
  //     getBrandsSlug();
  // }

  // const categoryFilter = (filters) => {
  //     page = 1;
  //     filter = filters;
  //     brandSlug.data = [];
  //     getBrandsSlug();
  // }

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      console.log(filter);
      // getTrends();
      // if (page < lastPage && !load) {
      //     page++;

      //     // if (axios.isLoggedIn) {
      //     //     // brandSlug.loadingScroll = true;
      //     //     // load = true;
      //     //     // axios.authApi.get('/aCollection?brand_slug=' + route.params.slug, {
      //     //     //     params: {
      //     //     //         page: page,
      //     //     //         sort: sort,
      //     //     //         stock: inStock,
      //     //     //         category: filter,
      //     //     //     }
      //     //     // }).then((resp) => {
      //     //     //     saveProductCount(resp.data.data.total)
      //     //     //     if (resp.status) {

      //     //     //         for (let i in resp.data.data.data) {
      //     //     //             brandSlug.data.data.push(resp.data.data.data[i]);
      //     //     //         }
      //     //     //         lastPage = resp.data.data.last_page;
      //     //     //         load = false;
      //     //     //     } else {
      //     //     //         brandSlug.error = resp.data.error;
      //     //     //     }
      //     //     // }).catch(function(error) {
      //     //     //     console.log(error);
      //     //     // }).finally(() => (brandSlug.loadingScroll = false));
      //     // } else {
      //     //     // brandSlug.loadingScroll = true;
      //     //     // load = true;
      //     //     // axios.api.get('/collection?brand_slug=' + route.params.slug, {
      //     //     //     params: {
      //     //     //         page: page
      //     //     //     }
      //     //     // }).then((resp) => {
      //     //     //     saveProductCount(resp.data.data.total)
      //     //     //     if (resp.status) {

      //     //     //         for (let i in resp.data.data.data) {
      //     //     //             brandSlug.data.data.push(resp.data.data.data[i]);
      //     //     //         }
      //     //     //         lastPage = resp.data.data.last_page;
      //     //     //         load = false;
      //     //     //     } else {
      //     //     //         brandSlug.error = resp.data.error;
      //     //     //     }
      //     //     // }).catch(function(error) {
      //     //     //     console.log(error);
      //     //     // }).finally(() => (brandSlug.loadingScroll = false));
      //     // }
      // }
    }
  };
  // this will register the event when the component is mounted on the DOM
  onMounted(() => {
    // window.addEventListener('scroll', handleScroll)
  });

  // We then unregister the listener when the component is removed from the DOM
  onUnmounted(() => {
    // window.removeEventListener('scroll', handleScroll)
    // saveProductCount(0);
  });

  // const banner = reactive({
  //     loading: false,
  //     data: [],
  //     name: '',
  //     image: '',
  //     error: ''

  // });

  // const getBanner = async() => {
  //     // if (axios.isLoggedIn) {
  //     banner.loading = true;
  //     axios.api.get('/collectionDetails?brand_slug=' + route.params.slug).then((resp) => {
  //         if (resp.status) {
  //             banner.data = resp.data.data;
  //         } else {
  //             banner.error = resp.data.error;
  //         }
  //     }).catch(function(error) {
  //         console.log(error);
  //     }).finally(() => (banner.loading = false));
  //     // } else {

  //     // }
  // }

  // const addtowatchlist = async(product_id, status) => {
  //     console.log(product_id);
  //     console.log(status);
  //     axios.authApi.post('/addWatchlist', {
  //         product_id: product_id,
  //         is_active: status
  //     }).then((resp) => {
  //         if (status) {
  //             // brandSlug.watchlist = brandSlug.watchlist != null ? 1 : 0;
  //             console.log(resp);
  //         } else {
  //             console.log(resp.data.error);
  //         }
  //     }).catch(function(error) {
  //         console.log(error);
  //     });

  // };

  return {
    states,
    getStates,
    brands,
    getbrands,
    categories,
    getCategories,
    trendBest,
    trendUnique,
    getTrends,

    // newBrands,
    // getNewBrands,
    // brandSlug,
    // getBrandsSlug,
    // brands,
    // getbrands,
    // banner,
    // getBanner,
    isLoggedIn: axios.isLoggedIn,
    // sortChange,
    // stock,
    // categoryFilter,
    // addtowatchlist
  };
}
