<template>
  <div class="brand w-full pb-10">
    <div
      class="collection-banner-container"
      :style="{
        'background-color': banner.data.background_color || 'rgb(121, 95, 70)',
      }"
    >
      <Banner title="Clearance" :toggle="false">
        <template #logo>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            class="w-24 h-24"
          >
            <path
              fill="#795f46"
              fill-rule="evenodd"
              d="M5 10H3.333a6.667 6.667 0 102.275-5.016L5.07 4.61a.5.5 0 00-.786.423l.074 2.687a.5.5 0 00.656.46l2.558-.843a.5.5 0 00.13-.884l-.676-.473A5 5 0 115 10z"
              clip-rule="evenodd"
            />
          </svg>
        </template>
        <template #header>
          <ol role="list" class="breadcrumb">
            <li>
              <div class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </div>
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span><a href="#">Clearance</a>
              </div>
            </li>
          </ol>
        </template>
        <template #footer>
          <div class="-mb-8">
            <Sorting @onSort="onSortChanged" />
            <SortFilterMobile @onSort="onSortChanged" />
          </div>
          <!-- Footer Slot -->
        </template>
      </Banner>
    </div>
    <div class="py-10 max-container">
      <div class="flex items-center space-x-4 pb-5">
        <FilterBadge />
      </div>
      <ListLoaderWrapper :isLoading="products.loading" />
      <ItemsList
        :items="products"
        :filter="filters"
        :loading="products.loading"
      />
      <ListLoaderWrapper class="mt-5" :isLoading="products.onScrollLoading" />
    </div>
  </div>
</template>

<script>
import { computed, watch, reactive } from "vue";
import Banner from "../common/Banner.vue";
import ItemsList from "@/views/pages/common/ItemsList.vue";
import { mapGetters, useStore } from "vuex";
import ListLoaderWrapper from "@/components/loaders/ListLoaderWrapper.vue";
import Common from "../../../modules/Common";
import SortFilterMobile from "@/components/SortFilterMobile.vue";

export default {
  name: "Home",
  components: {
    Banner,
    ItemsList,
    ListLoaderWrapper,
    SortFilterMobile,
  },
  watch: {
    filters: {
      handler: "filterCategory",
      deep: true,
    },
    priceRange: {
      handler: "priceOnChange",
      deep: true,
    },
  },
  beforeMount() {
    this.$store.dispatch("getCart", { ax: null });
  },
  computed: {
    ...mapGetters({ filters: "getSelected" }),
    ...mapGetters({ priceRange: "getPriceRange" }),
  },
  methods: {
    filterCategory() {
      this.onFilterChanged();
    },
    priceOnChange() {
      if(this.$store.getters.getPriceCheck) this.onPriceRangeChanged();
    },
  },
  setup() {
    const banner = reactive({
      loading: false,
      data: [],
      error: "",
    });

    const name = "clearance";
    const LoginURL = "/clearance";
    const LogoutURL = "/clearance-products";

    const store = useStore();

    const {
      products,
      onSortChanged,
      onStockChanged,
      onFilterChanged,
      onPriceRangeChanged,
      maxPrice,
    } = Common({ name }, { LoginURL, LogoutURL });
    maxPrice();
    onSortChanged();

    const currentShipping = computed(() => store.getters.getSelectedAddress);

    watch(currentShipping, () => {
      if(store.getters.getAddressChange) {
        store.dispatch("setAddressChange", false);
        onSortChanged();
      }
    });

    return {
      banner,
      products,
      onSortChanged,
      onStockChanged,
      onFilterChanged,
      onPriceRangeChanged,
    };
  },
};
</script>

<style lang="scss" scoped>
.collection-banner-container {
  background-image: url("/images/lines-pattern.png");
}
</style>
